import React from "react";
import * as cx from "classnames";
import "./CidButton.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import signInIcon from "../../assets/svgs/signIn.svg";
import signInBlackIcon from "../../assets/svgs/signIn-black.svg";

export default function CidButton(props) {
  let history = useNavigate();
  // let contactClick = (method) => {
  //   history.push({
  //     pathname: "/contact-us",
  //     state: { contactMethod: method },
  //   });
  // };
  return (
    <div>
      {props.href ? (
        <a
          href={props.href}
          download={props.download}
          className="cid-btn-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          {props.buttonText ? (
            <button
              type="button"
              className={cx(
                "cid-btn 1",
                { "btn-outline": props.outline },
                { "btn-outline-color": props.outlineColor },
                { "digitalId-btn-outline": props.outlineClass }
              )}
            >
              {props.buttonText}
              <i className="fa fa-chevron-right btn-icon"></i>
            </button>
          ) : props.showwhiteIcon ? (
            <button type="button" className={cx("cid-btn img-btn")}>
              <img src={signInIcon} alt="Sign In tab on Credence ID website" />
            </button>
          ) : (
            <button type="button" className={cx("cid-btn img-btn")}>
              <img src={signInBlackIcon} alt="CredenceID-sign-in" />
            </button>
          )}
        </a>
      ) : props.link === "/contact-us-freeDemo" ? (
        <Link
          to={
            props.link
              ? {
                  pathname: "/contact-us",
                  state: "freeDemo",
                }
              : ""
          }
          className="cid-btn-link"
        >
          <button
            type="button"
            className={cx(
              "cid-btn",
              { "btn-outline": props.outline },
              { "btn-outline-color": props.outlineColor }
            )}
          >
            {props.buttonText}
            <i className="fa fa-chevron-right btn-icon"></i>
          </button>
        </Link>
      ) : // <button
      // 		type="button"
      // 		className={cx(
      // 			'cid-btn',
      // 			{'btn-outline': props.outline},
      // 			{'btn-outline-color': props.outlineColor}
      // 		)}
      // 		onClick ={() => contactClick('freeDemo')}
      // 	>
      // 		{props.buttonText}
      // 		<i className="fa fa-chevron-right btn-icon"></i>
      // </button>
      props.link === "/contact-us-digitalId" ? (
        <Link
          to={
            props.link
              ? {
                  pathname: "/contact-us",
                  state: "digitalId",
                }
              : ""
          }
          className="cid-btn-link"
        >
          <button
            type="button"
            className={cx(
              "cid-btn",
              { "btn-outline": props.outline },
              { "btn-outline-color": props.outlineColor }
            )}
          >
            {props.buttonText}
            <i className="fa fa-chevron-right btn-icon"></i>
          </button>
        </Link>
      ) : props.link === "/contact-us-generalEnquiry" ? (
        <Link
          to={
            props.link
              ? {
                  pathname: "/contact-us",
                  state: "generalEnquiry",
                }
              : ""
          }
          className="cid-btn-link"
        >
          <button
            type="button"
            className={cx(
              "cid-btn",
              { "btn-outline": props.outline },
              { "btn-outline-color": props.outlineColor }
            )}
          >
            {props.buttonText}
            <i className="fa fa-chevron-right btn-icon"></i>
          </button>
        </Link>
      ) : (
        // <button
        // 		type="button"
        // 		className={cx(
        // 			'cid-btn',
        // 			{'btn-outline': props.outline},
        // 			{'btn-outline-color': props.outlineColor}
        // 		)}
        // 		onClick ={() => contactClick('generalEnquiry')}
        // 	>
        // 		{props.buttonText}
        // 		<i className="fa fa-chevron-right btn-icon"></i>
        // </button>
        <Link
          to={props.link ? props.link : ""}
          className={`${props.className} cid-btn-link`}
        >
          <button
            type="button"
            className={cx(
              "cid-btn",
              { "btn-outline": props.outline },
              { "btn-outline-color": props.outlineColor }
            )}
            disabled={props.disabled}
          >
            {props.buttonText}
            {!props.hideIcon && (
              <i className="fa fa-chevron-right btn-icon"></i>
            )}
          </button>
        </Link>
      )}
    </div>
  );
}
